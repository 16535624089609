import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { FourberieSign } from "../components/svg-assets/Icons";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("not-found.html-title")} />
      <div class="fl-grid">
        <div class="fl-centered-half fl-center fl-svg-width-wrapper" style={{ marginTop: 100 }}>
          <FourberieSign />
        </div>
        <div class="fl-centered-half fl-center" style={{ marginTop: 48, marginBottom: 48 }}>
          <h1>
            <Trans i18nKey="not-found.title" />
          </h1>
          <p>
            <Trans i18nKey="not-found.description" />
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
